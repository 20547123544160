.spinner {
	display: block;
	margin-top: 2em;
	margin-right: auto;
	margin-left: auto;
	width: 4em;
	height: 4em;
  padding: 7px;
	border-radius: 50%;
	transform: scale(.7);
}

.large {
  margin-top: 4em;
  transform: scale(1.5)
}

.spinner-wrapper {
	position: relative;
	width: 4em;
	height: 4em;
	border-radius: 100%;
	left: calc(50% - 2em);
}

.spinner-wrapper::after {
	content: "";
	background: #fff;
	border-radius: 50%;
	width: 3em;
	height: 3em;
	position: absolute;
	top: 0.5em;
	left: 0.5em;
} 

.rotator {
	position: relative;
	width: 4em;
	border-radius: 4em;
	overflow: hidden;
	animation: rotate 2000ms infinite linear;
}

.rotator:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #C3002F;
	border: 3px solid #fff;
	border-radius: 100%;
}

.inner-spin {
	background: #fff;
	height: 4em;
	width: 2em;
}

.inner-spin {
	animation: rotate-left 2500ms infinite cubic-bezier(0.445, 0.050, 0.550, 0.950);
	border-radius: 2em 0 0 2em;
	transform-origin: 2em 2em;
}

.inner-spin:last-child {
	animation: rotate-right 2500ms infinite cubic-bezier(0.445, 0.050, 0.550, 0.950);
	margin-top: -4em;
	border-radius: 0 2em 2em 0;
	float: right;
	transform-origin: 0 50%;
}

@keyframes rotate-left {
	60%, 75%, 100% {
    	transform: rotate(360deg);
  	}
}

@keyframes rotate {
	0% {
    	transform: rotate(0);
  	}
  	100% {
    	transform: rotate(360deg);
  	}
}

@keyframes rotate-right {
	0%, 25%, 45% {
    	transform: rotate(0);
  	}

  	100% {
    	transform: rotate(360deg);
  	}
}
